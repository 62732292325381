require('./bootstrap');
require('./../../node_modules/daterangepicker');
require('./../../node_modules/@event-calendar/core')
require('./../../node_modules/@event-calendar/day-grid')
require('./../../node_modules/@event-calendar/list')
require('./../../node_modules/@event-calendar/resource-timeline')
require('./../../node_modules/@event-calendar/resource-time-grid')
require('./../../node_modules/@event-calendar/time-grid')
require('./../../node_modules/@event-calendar/interaction')

$(document).ready(function() {
    try {
        moment.locale('pt-br');
        $("#dashboardDialog").modal({show: false});
    } catch (e) {}
});
